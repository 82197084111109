import ExcelJS from 'exceljs';

export const exportToExcel = (jsonData, fileName) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Contributions');
  worksheet.columns = Object.keys(jsonData[0]).map((key) => ({ header: key, key }));
  jsonData.forEach((data) => worksheet.addRow(data));
  workbook.xlsx.writeBuffer().then((excelBuffer) => {
    saveAsExcelFile(excelBuffer, fileName);
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data);
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
