import React from 'react';
import { Grid, IconButton } from '@mui/material';
import '../css/_footer.scss';
import { useTranslation } from 'react-i18next';
import logoFooter from '../assets/images/logo_mymoojo_white.png';
import logoEsus from '../assets/images/logo-esus.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import LangSwitcher from './LangSwitcher';

function Footer() {
  const { t } = useTranslation();

  const TikTokIcon = ({ color = '#000000' }) => {
    return (
      <svg fill={color} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width='100%' height='100%'>
        <path d='M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z' />
      </svg>
    );
  };

  return (
    <Grid className='app-footer'>
      <div className='container'>
        <Grid container spacing={4}>
          <Grid container item alignItems={'center'} spacing={2}>
            <Grid container item xs={12} sm={3} alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
              <Grid item xs={6}>
                <img
                  src={logoFooter}
                  width='100%'
                  alt='MyMoojo'
                  onClick={() => window.open('https://mymoojo.com', '_self')}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid item xs={6}>
                <img src={logoEsus} height='auto' alt='MyMoojo Esus' />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={5} style={{ color: 'white', fontSize: '0.8rem', textAlign: 'justify' }}>
              {t('footer_text')}
            </Grid>
            {/*<Grid*/}
            {/*  container*/}
            {/*  item*/}
            {/*  xs={12}*/}
            {/*  sm={3}*/}
            {/*  style={{ color: 'white', fontSize: '0.8rem' }}*/}
            {/*  justifyContent={'flex-end'}*/}
            {/*  marginBottom={'auto'}*/}
            {/*>*/}
            {/*  <LangSwitcher />*/}
            {/*</Grid>*/}
            <Grid container item xs={12} sm={4} justifyContent={'center'} spacing={2}>
              <Grid item>
                <IconButton
                  className='delete-icon'
                  onClick={() => window.open('https://www.linkedin.com/company/my-moojo/')}
                  aria-label='mymoojo linkedin'
                  sx={{ width: '40px', height: '40px', backgroundColor: '#fff', color: 'black' }}
                >
                  <LinkedInIcon width={30} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  className='delete-icon'
                  onClick={() => window.open('https://facebook.com/mymoojo/')}
                  aria-label='mymoojo facebook'
                  sx={{ width: '40px', height: '40px', backgroundColor: '#fff', color: 'black' }}
                >
                  <FacebookIcon width={30} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  className='delete-icon'
                  onClick={() => window.open('https://www.instagram.com/mymoojo/')}
                  aria-label='mymoojo instagram'
                  sx={{ width: '40px', height: '40px', backgroundColor: '#fff', color: 'black' }}
                >
                  <InstagramIcon width={30} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  className='delete-icon'
                  onClick={() => window.open('https://www.tiktok.com/@my.moojo/')}
                  aria-label='mymoojo tiktok'
                  sx={{ width: '40px', height: '40px', backgroundColor: '#fff', color: 'black' }}
                >
                  <TikTokIcon width={30} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={5} alignItems={'center'} justifyContent={'center'}>
            <Grid container item spacing={2} xs={12} sm={9} justifyContent={'center'}>
              <Grid item>
                <a href='/legal' target='_self' style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}>
                  {t('footer_menu_3')}
                </a>
              </Grid>
              <Grid item style={{ color: '#fff' }}>
                •
              </Grid>
              <Grid item>
                <a
                  href='/docs/CGVU MY MOOJO.pdf'
                  target='blank'
                  style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}
                >
                  {t('footer_menu_2')}
                </a>
              </Grid>
              <Grid item style={{ color: '#fff' }}>
                •
              </Grid>
              <Grid item>
                <a
                  href='https://mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf'
                  target='blank'
                  style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}
                >
                  {t('footer_menu_9')}
                </a>
              </Grid>
              <Grid item style={{ color: '#fff' }}>
                •
              </Grid>
              <Grid item>
                <a
                  href='https://www.mymoojo.com/politique-de-confidentialite/'
                  target='blank'
                  style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}
                >
                  {t('footer_menu_7')}
                </a>
              </Grid>
              <Grid item style={{ color: '#fff' }}>
                •
              </Grid>
              <Grid item>
                <a href='/support' target='blank' style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}>
                  {t('footer_menu_8')}
                </a>
              </Grid>
              <Grid item style={{ color: '#fff' }}>
                •
              </Grid>
              <Grid item>
                <a
                  href='https://tally.so/r/w2vl4e'
                  target='blank'
                  style={{ color: '#fff', textDecoration: 'none', fontSize: '12px' }}
                >
                  {t('footer_menu_4')}
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default Footer;
